@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Montserrat:wght@300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Sans+Pro:wght@400;600;700&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Cabin", sans-serif;
}
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
}

::-webkit-scrollbar {
    display: none;
    // width: 10px;
}

// ::-webkit-scrollbar-track{
//     background: #e0e0e0;
// }

// ::-webkit-scrollbar-thumb{
//     background: #888;
// }

// ::-webkit-scrollbar-track:hover{
//     background: #555;
// }
