@import '../../../css-config/mixins.scss';

.shop-by-category{
    margin: 25px 0;
    @include md{
        margin: 50px 0;
    }
    .categories{
        display: flex;
        flex-flow: wrap;
        padding: 0 5px;
        gap: 10px;
        .category{
            box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
            background-color: rgba(0,0,0,0.05);
            width: calc(50% - 5px);
            cursor: pointer;
            overflow: hidden;
            @include md{
                width: 25px;
                width: calc(25% - 10px);
            }
            img{
                width: 100%;
                display: block;
                transition: all ease 0.3s;
                height: 200px;
            }
            &:hover {
                img{
                    transform: scale(1.08);
                }
            }
            .category--name{
                padding: 10px;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                color: #000;
                font-family: sans-serif;
                @include md{
                    font-size: 20px;
                }
            }
        }
    }
}