@import '../../css-config/mixins.scss';

.products--container{
    margin: 50px 0;
    @include md{
        margin: 75px 0;
    }
    .sec--heading{
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        text-align: left;
        @include md{
            margin-bottom: 35px;
            font-size: 24px;
        }
        &:after{
            content: "";
            display: block;
            margin-top: 5px;
            width: 50px;
            height: 3px;
            background-color: #8e2de2;
            @include md{
                margin-top: 10px;
            }
        }
    }
    .products{
        display: flex;
        flex-flow: wrap;
        gap: 10px;
        @include md{
            gap: 20px;
        }
    }
}