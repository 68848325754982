@import '../../../../css-config/mixins.scss';

.header--container{
    width: 100%;
    padding: 0 20px;
    z-index: 99;
    background-color: #212121;
    color: white;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 100;
    box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
    @include md {
        padding: 0 40px;
    }
    .header--content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 65px;
        max-width: 1200px;
        margin: 0 auto;
        @include md {
            height: 80px;
            padding: 0px 20px;
        }
        .logo{
            font-size: 22px;
            cursor: pointer;
            @include xl {
                font-size: 24px;
            }
        }
        .left {
            list-style-type: none;
            display: none;
            gap: 25px;
            @include md {
                display: flex;
            }
            span {
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                cursor: pointer;
                &:hover {
                    opacity: 0.6;
                }
            }
        }
        .right {
            display: flex;
            align-items: center;
            gap: 20px;
            @include md {
                gap: 25px;
            }
            svg {
                font-size: 20px;
                cursor: pointer;
                @include md {
                    font-size: 24px;
                }
                &:hover {
                    opacity: 0.6;
                }
            }
            button{
                width: 120px;
                padding: 7px 0;
                background-color: rgb(79, 88, 107);
                border: 1px solid rgb(79, 88, 107);
                border-radius: 5px;
                color: white;
                font-size: 0.9rem;
                font-weight: 600;
                font-family: sans-serif;
                outline: none;
                cursor: pointer;
                @include md{
                    font-size: 1rem;
                }
            }
        }
    }
}