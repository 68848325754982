@import '../../../css-config/mixins.scss';

.product--card{
    width: calc(50% - 5px);
    margin-bottom: 20px;
    box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
    @include md{
        width: calc(25% - 15px);
    }
    .product--details{
        text-align: left;
        img{
            transition: all ease 0.3s;
            display: block;
            width: 100%;
            height: 220px;
            @include md{
                height: 300px;
            }
        }
        .name{
            font-size: 14px;
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: rgb(40, 44, 48);
            padding: 8px 10px;
            @include md{
                font-size: 16px;
                margin-bottom: 10px;
            }
        }
        .price{
            font-size: 18px;
            padding: 5px 10px;
            color: rgb(46, 51, 56);
            @include md{
                font-size: 22px;
            }
        }
    }
    &:hover{
        img{
            transform: scale(1.08);
        }
    }
}