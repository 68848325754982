@import '../../css-config/mixins.scss';

.signup--container{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 40px;
    width: 80%;
    padding: 20px;
    @include md{
        width: 450px;
    }
    .logo{
        font-size: 2rem;
        color: rgb(56, 61, 72);
        font-family: sans-serif;
        font-weight: 700;
        cursor: pointer;
    }
    .signup--content{
        box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin-top: 20px;
        padding: 40px 20px;
        border: 1px solid rgba(0,0,0,0.1);
        .signup--heading{
            font-size: 2rem;
            font-weight: 700;
            font-family: sans-serif;
            text-align: left;
        }
        .signup--form{
            width: 100%;
            margin-top: 30px;
            .form--group{
                display: flex;
                flex-direction: column;
                margin-bottom: 1rem;
                gap: 5px;
                label{
                    font-size: 1.2rem;
                    font-weight: 700;
                    margin-bottom: 0.5rem;
                    font-family: sans-serif;
                    text-align: left;
                }
                input{
                    height: 35px;
                    background-color: white;
                    width: 98%;
                    border-radius: 3px;
                    border: 1px solid lightgray;
                    color: rgba(0, 0, 0, 0.7);
                    margin-bottom: 10px;
                    padding-left: 10px;
                    &:focus{
                        outline: none;
                        border: 1px solid rgb(0, 0, 0);
                    }
                }
                span{
                    font-size: 13px;
                    text-align: left;
                    font-weight: 600;
                }
                button{
                    background-color: #a052e5;
                    border: 1px solid;
                    width: 100%;
                    height: 35px;
                    cursor: pointer;
                    color: white;
                    border-color: #8e2de2;
                    border-radius: 5px;
                    margin-top: 10px;
                    font-weight: 700;
                    font-family: sans-serif;
                    font-size: 15px;
                }
            }
        }
        p{
            margin-top: 15px;
            font-size: 13px;
        }
        .login--registerButton{
            border-radius: 2px;
            width: 100%;
            height: 35px;
            cursor: pointer;
            border: 1px solid;
            margin-top: 10px;
            border-color: darkgray;
            border-radius: 5px;
        }
    }
}