@import '../../../css-config/mixins.scss';

.registration--page{
    text-align: left;
    width: 100%;
    padding: 25px;
    .title{
        background-color: black;
        padding: 25px;
        margin: -25px;
        margin-bottom: 0px;
        h1{
            max-width: 1000px;
            font-size: 1.4rem;
            font-weight: 700;
            font-family: sans-serif;
            color: white;
            margin: 0 auto;
            cursor: pointer;
            @include x1200{
                font-size: 2rem;
            }
        }
    }
    .field{
        width: 100%;
        display: flex;
        flex-direction: column;
        @include md{
            width: 46%;
        }
        input {
            width: 100%;
            height: 35px;
            border-radius: 5px;
            border: 1px solid rgba(0,0,0,0.5);
            padding: 5px;
            font-size: 1.2rem;
            font-weight: 400;
            font-family: sans-serif;
            color: rgb(56, 61, 72);
        }
    }
    .reg-form-container{
        box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 40px auto;
        max-width: 1000px;
        padding: 40px;
        @include md{
            align-items: center;
            justify-content: center;
        }
        .registration--form{
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            gap: 20px;
            @include md{
                flex-direction: row;
                flex-wrap: wrap;
                margin: 20px auto;
                justify-content: space-between;
            }
            textarea{
                width: 100%;
                height: 35px;
                border-radius: 5px;
                border: 1px solid rgba(0,0,0,0.5);
                padding: 5px;
                font-size: 1.2rem;
                font-weight: 400;
                font-family: sans-serif;
                color: rgb(56, 61, 72);
            }
        }
    }
    .payment--details{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 5px;
        margin-top: 20px;
        .payment--title{
            margin-bottom: 10px;
        }
        .payment--methods{
            display: flex;
            flex-direction: column;
            gap: 5px;
            .payment--method{
                display: flex;
                gap: 5px;
                color: rgba(0,0,0,0.7);
            }
        }
        .method--details{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 20px;
            margin: 10px 0px;
        }
    }
    .pass--field{
        width: 100%;
        display: flex;
        flex-direction: column;
        @include md{
            width: 40%;
        }
        input {
            width: 100%;
            height: 35px;
            border-radius: 5px;
            border: 1px solid rgba(0,0,0,0.5);
            padding: 5px;
            font-size: 1.2rem;
            font-weight: 400;
            font-family: sans-serif;
            color: rgb(56, 61, 72);
        }
    }
    button{
        width: 200px;
        padding: 10px;
        cursor: pointer;
        font-size: 16px;
        color: white;
        background: #8e2de2;
        border: 1px solid #7835b2;
        border-radius: 5px;
        align-self: center;
    }
    .check--field{
        display: flex;
        gap: 10px;
    }
}