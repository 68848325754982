@import '../../../../css-config/mixins.scss';

.seller--banner{
    width: 100%;
    .banner--container{
        width: 100%;
        max-width: 1200px;
        display: flex;
        flex-direction: column;
        margin: 40px auto;
        padding: 20px;
        text-align: left;
        @include md{
            flex-direction: row;
            justify-content: space-between;
        }
        .banner--left{
            display: flex;
            flex-direction: column;
            gap: 20px;
            span{
                font-size: 1.2rem;
                font-weight: 700;
                font-family: sans-serif;
                color: rgb(56, 61, 72);
                margin-bottom: 20px;
                background-color: #a354e8;
                border: 1px solid #750ad2;
                color: white;
                border-radius: 5px;
                width: 220px;
                padding: 5px;
                text-align: center;
            }
            h1{
                font-size: 3rem;
                font-weight: 700;
                font-family: sans-serif;
                color: rgb(56, 61, 72);
                margin-bottom: 20px;
            }
            p{
                font-size: 1.2rem;
                font-weight: 400;
                font-family: sans-serif;
                color: rgb(56, 61, 72);
                margin-bottom: 20px;
            }
            button{
                width: 200px;
                height: 50px;
                border-radius: 5px;
                border: none;
                background-color: rgb(79, 88, 107);
                border: 1px solid rgb(79, 88, 107);
                color: white;
                font-size: 1.2rem;
                font-weight: 700;
                font-family: sans-serif;
                cursor: pointer;
                margin-top: -20px;
                transition: all 0.3s ease-in-out;
                &:hover{
                    background-color: rgb(56, 61, 72, 0.8);
                }
            }
        }
        .banner--right{
            img{
                width: 300px;
                height: 220px;
                @include md{
                    width: 500px;
                    height: 350px;
                }
            }
        }
    }
}
